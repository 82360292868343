import React, { useEffect, useState } from 'react';
import './App.css';
import { FaInstagram, FaMoon, FaSun } from 'react-icons/fa';
import confetti from 'canvas-confetti';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    // Trigger confetti on page load
    const duration = 3 * 1000; // 5 seconds
    const animationEnd = Date.now() + duration;

    const colors = ['#ff7e5f', '#feb47b', '#86a8e7', '#91eae4', '#00c9a7', '#0078d7'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < animationEnd) {
        requestAnimationFrame(frame);
      }
    })();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setIsSubmitted(true);

      // Success confetti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { x: 0.5, y: 0.5 },
        colors: ['#ff7e5f', '#00c9a7', '#0078d7', '#feb47b'],
      });

      // Clear the email input after submission
      setEmail('');
      setTimeout(() => setIsSubmitted(false), 3000); // Reset success message after 3 seconds
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('light-mode');
  };

  return (
    <div className={`rootContainer ${isDarkMode ? '' : 'light-mode'}`}>
      {/* Dark Mode Toggle */}
      <div className="dark-mode-toggle" onClick={toggleDarkMode}>
        {isDarkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
      </div>

      <div className="coming-soon-container">
        <header className="header-container">
          <span className="header-part">The Tech</span>
          <span className="header-gradient">Traveller</span>
          <span className="header-part">Guy's Blog</span>
        </header>
        
        <h1 className="coming-soon-text">Coming Soon</h1>

        {/* Sign-Up Box */}
        <div className="signup-box">
          <form onSubmit={handleSubmit} className="signup-form">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
              required
            />
            <button type="submit" className="signup-button">
              Let's catch up!
            </button>
          </form>
          {isSubmitted && <p className="success-message">Thanks for your interest! 🎉</p>}
        </div>

        {/* Footer Section */}
        <footer className="footer-container">
          <span className="footer-text">
            <span>&#169; 2024</span> <span>@engineer_no_486</span>
          </span>
          <a
            href="https://www.instagram.com/engineer_no_486/"
            target="_blank"
            rel="noopener noreferrer"
            className="instagram-icon"
          >
            <FaInstagram size={30} />
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
